<template>
  <div id="sub-header">
    <a class="back" v-if="btn_back" @click="back"></a>
    <p class="title" v-if="title !== 'hidden'">{{ title }}</p>
    <a class="close" v-if="btn_close" @click="back"></a>
  </div>
</template>

<script>
export default {
  name: 'Subheader',
  computed: {
    title() {
      return this.$route.params.subTitle ? this.$route.params.subTitle : this.$route.meta.title || ''
    },
    btn_close() {
      return this.$route.meta.btn_close || false
    },
    btn_back() {
      return this.$route.meta.btn_back || false
    }
  },
  methods:{
    back(){
      this.$router.back()
    }
  }
}
</script>
