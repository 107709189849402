<template>
  <div id="app" class="app">
    <base-layout />
  </div>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout'

export default {
  name: 'App',
  components: {BaseLayout}
}
</script>
