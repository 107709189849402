import axios from '@/api/axios';
import _ from 'lodash';
import {mapKeysCamelCase, mapKeysSnakeCase} from '@/utils/deepKeysMap';
import $dayjs from 'dayjs';
import qs from 'qs';
import {EO_EX_SAVE, EO_USER} from '@/constants/Constants';
import {deepFreeze} from '@/utils/deepFreeze';
import {deepCopy} from '@/utils/deepCopy';

let initialState = {
  status: {
    page:1,
    totalPage:1,
    list: [],
    history: []
  },
}

deepFreeze(initialState)

export const DO_LIST = 'exam/doList'
export const DO_COMPLETE = 'exam/doComplete'
export const GET_LIST = 'exam/getList'
export const ANSWER = 'exam/answer'
export const DO_LIKE = 'exam/doLike'
export const DO_DIS_LIKE = 'exam/doDisLike'
export const START = 'exam/start'
export const RESET = 'exam/reset'
export const RESUME = 'exam/resume'
export const START_TIME = 'exam/startTime'
export const DO_HISTORY = 'exam/doHistory'
export const GET_HISTORY = 'exam/getHistory'
export const DO_INCORRECT = 'exam/doIncorrect'
export const GET_INCORRECT = 'exam/getIncorrect'
export const DO_EXAM_HISTORY = 'exam/doExamHistory'
export const GET_EXAM_HISTORY = 'exam/getExamHistory'
export const GET_EXAM_HISTORY_DATA = 'exam/getExamHistoryData'
export const DO_EXAM_EXCLUDE = 'exam/doExamExclude'
export const DO_STATISTICS = 'exam/doStatistics'
export const GET_STATISTICS = 'exam/getStatistics'
export const DO_READ_STATISTICS = 'exam/doReadStatistics'

export const exam = {
  namespaced: true,
  state: deepCopy(initialState),
  actions: {
    doList({commit, state}, param){
      //문제 리스트 조회
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/get_exam.php',
        data: qs.stringify(mapKeysSnakeCase(param))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            // console.log(response.data.sql)
            finalData = _.merge(finalData, response.data)
            commit('examList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    doLike({commit, state}, param){

      //문제 좋아요
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/exam_good.php',
        data: qs.stringify(mapKeysSnakeCase(param))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    doDisLike({commit, state}, param){
      //문제 싫어요
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/exam_not_good.php',
        data: qs.stringify(mapKeysSnakeCase(param))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    doComplete({commit, state}, param){
      //문제 풀이 완료
      let finalData = {
        result_code: '00',
        message: ''
      }

      const form = {
        ...param,
        ...state.status,
        total:state.status.list.filter(value => value.a === value.answer).length
      }

      const options = {
        method: 'POST',
        url: '/v1/exam_complete.php',
        data: qs.stringify(mapKeysSnakeCase(form))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('complete')
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    doHistory({commit, state}, date){
      //문제 풀이 내역
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/exam_history.php',
        data: qs.stringify(mapKeysSnakeCase({date: date}))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('historyList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    doIncorrect({commit, state}, date){
      //오답노트
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/exam_incorrect.php',
        data: qs.stringify(mapKeysSnakeCase({date: date}))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('incorrectList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    doExamHistory({commit, state}, param){
      //문제 풀이 내역
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/get_exam_history.php',
        data: qs.stringify(param)
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('examHistoryList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    doExamExclude({commit, state}, qu_id){
      //문제 다시 풀지 않기
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/exam_exclude.php',
        data: qs.stringify({qu_id: qu_id})
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    doStatistics({commit, state}){

      //시험통계 조회
      if (state.page > state.totalPage) {
        return
      }

      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/exam_statistics.php',
        params: {
          'page': state.status.page
        }
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('examStatisticsList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    doReadStatistics({commit, state}, wr_id){
      //시험통계 읽음처리
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/exam_read_statistics.php',
        data: qs.stringify({wr_id: wr_id})
      }

      return axios(options).then(
        response => {

          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
  },
  mutations: {
    examList(state, param) {
      //문제 리스트
      state.status = {
        ...state.status,
        list: param.list
      }
    },
    start(state, index){
      if (index === undefined){
        state.status.start = $dayjs()
        return
      }

      //문제풀이 시작
      state.status.list[index].start = $dayjs()

      //중간저장
      localStorage.setItem(EO_EX_SAVE, JSON.stringify(state.status))
    },
    answer(state, param){
      const {index, answer} = param

      //답변 수정
      if (state.status.list[index].a !== undefined){
        state.status.list[index].a = answer
        return
      }

      //답변
      state.status.list[index].a = answer
      state.status.list[index].end = $dayjs.duration($dayjs().diff(state.status.list[index].start))
      state.status.list[index].endTime = $dayjs().diff(state.status.list[index].start)

      //중간저장
      localStorage.setItem(EO_EX_SAVE, JSON.stringify(state.status))
    },
    complete(state){
      //중간저장 삭제
      localStorage.removeItem(EO_EX_SAVE)
    },
    reset(state){
      //상태 초기화
      Object.assign(state, deepCopy(initialState))
    },
    resume(state){
      //중간 저장 데이터 복구
      const savedState = JSON.parse(localStorage.getItem(EO_EX_SAVE))
      state.status = {...savedState}
    },
    historyList(state, param) {
      //문제풀이내역 리스트
      state.status = {
        ...state.status,
        history: param.list
      }
    },
    incorrectList(state, param) {
      //오답노트 리스트
      state.status = {
        ...state.status,
        incorrect: param.list
      }
    },
    examHistoryList(state, param) {
      //문제풀이 상세
      state.status = {
        ...state.status,
        examHistory: param.list,
        examHistoryData: {
          reg_date: param.reg_date,
          elapsed: param.elapsed
        }
      }
    },
    examStatisticsList(state, param) {
      //문제풀이 상세
      state.status = {
        ...state.status,
        totalPage: param.max_page,
        page: +param.page + 1,
        statistics: [...state?.status?.statistics ?? [], ...param.list]
      }

    },
  },
  getters:{
    getList(state){
      //문제 리스트
      return state.status.list
    },
    startTime(state){
      //문제 풀이시간
      return state.status.start
    },
    getHistory(state){
      //문제풀이내역 리스트
      return state.status.history
    },
    getIncorrect(state){
      //오답노트 리스트
      return state.status.incorrect
    },
    getExamHistory(state){
      //문제풀이상세내역 리스트
      return state.status.examHistory
    },
    getExamHistoryData(state){
      //문제풀이상세내역 데이터
      return state.status.examHistoryData
    },
    getStatistics(state){
      //시험통계
      return state.status.statistics
    },
  }
}