import store from '../store'

export default {
  /**
   * Vue
   * @param {Vue} Vue
   */
  install: function (Vue) {
    Vue.config.globalProperties.$store = store;
    Vue.use(store)
  }
};