'use strict'

import axios from 'axios'
import {GET_TOKEN, LOGOUT} from '@/store/modules/auth.module';
import $store from '@/store'
import { isTablet } from 'mobile-device-detect';
import {v4 as uuidv4} from 'uuid';
import {EO_USER} from '@/constants/Constants';

const _axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  timeout: 60000
})

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    let uuid = localStorage.getItem('uuid')
    if (!uuid){
      uuid = uuidv4()
      localStorage.setItem('uuid', uuid)
    }
    config.headers.uuid = uuid
    config.headers.device = isTablet ? 'tablet' : 'mobile'

    try {
      let accessToken = $store.getters[GET_TOKEN]
      config.headers.eotoken = 'Bearer ' + accessToken
    } catch (e){
      // alert(e.message)
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

_axios.interceptors.response.use(
  (response) => {

    if (response.data.result_code === '04'){
      $store.dispatch(LOGOUT).then(
        (data) => {
          setTimeout(() => {
            window.reactNative.postMessage(JSON.stringify({method: 'exit', func: '', item: {}}))
          }, 1000)
        },
        error => {
          alert(error.message)
        }
      )
    }

    return response
  },
  async (error) => {
    const {
      config,
      response: {status, data}
    } = error

    return Promise.reject(new Error('통신에 오류가 있습니다.'))
  }
)

export default _axios
