import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/js/bootstrap.js'
import {VueCookieNext} from 'vue-cookie-next'
import VueDayjs from './plugins/vueDayjsPlugin'
import Store from './plugins/storePlugin'

const app = createApp(App)
app.use(VueDayjs)
app.use(VueCookieNext)
app.use(Store)
app.use(router)
app.mount('#app')