<template>
  <div>
    <slot/>
  </div>
</template>

<script>

import {NONE_LAYOUT} from '@/layouts/index';

export default {
  name: NONE_LAYOUT
}
</script>
