import {createRouter, createWebHistory} from 'vue-router'
import {EO_IS_INSTALLED, EO_TOKEN} from '@/constants/Constants';
import {NONE_LAYOUT} from '@/layouts';
import $store from '@/store/index'
import {REFRESH_TOKEN} from '@/store/modules/auth.module';
import {
  GUEST_SCREEN,
  keysForMeta,
  LOGIN_SCREEN,
  MAIN_SCREEN,
  Screens,
} from '@/views';

const routes = [
  {
    path: '',
    alias: '',
    name: '',
    meta: {
      layout: NONE_LAYOUT,
    },
    beforeEnter: async (to, from, next) => {
      const isInstalled = localStorage.getItem(EO_IS_INSTALLED)
      /***
       * 최초실행 - 게스트 홈 이동
       * 미로그인 - 로그인 이동
       * 로그인 - 메인 이동
       */
      if (!isInstalled){
        next({ path: `/${GUEST_SCREEN}` })
      } else {

        let token = localStorage.getItem(EO_TOKEN)

        if (token){
          await $store.dispatch(REFRESH_TOKEN, token).then(() => {
            next({ path: `/${MAIN_SCREEN}` })
          }).catch((err) => {
            alert(err.message)
            next({ path: `/${LOGIN_SCREEN}` })
          })

        } else {
          next({ path: `/${LOGIN_SCREEN}` })
        }
      }
    }
  }
]

Screens.forEach((value, key) => {
  routes.push({
    path: `/${key}`,
    name: key,
    component: () => value,
    meta: keysForMeta(key),
  })
})

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    let position = {}
    if (to.hash) {
      // const el = window.location.href.split('#')[1]
      const el = document.querySelector(to.hash.split('#')[1])
      if (el) {
        position = {
          el: to.hash,
          behavior: 'smooth'
        }
      }
    } else if (savedPosition) {
      position = savedPosition
    } else {
      position = {top: 0}  // Go to the top of the page if no hash
    }
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(position)
      }, 10)
    })
  }
})

export default router
