import axios from '@/api/axios';
import _ from 'lodash';
import {mapKeysCamelCase} from '@/utils/deepKeysMap';
import $dayjs from 'dayjs';
import {deepFreeze} from '@/utils/deepFreeze';
import {deepCopy} from '@/utils/deepCopy';

let initialState = {
  status: {
    list: []
  },
}

deepFreeze(initialState)

export const DO_LIST = 'schedule/doList'
export const GET_LIST = 'schedule/getList'
export const RESET = 'schedule/reset'

export const schedule = {
  namespaced: true,
  state: deepCopy(initialState),
  actions: {
    doList({commit, state}){
      //일정 리스트 조회
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'GET',
        url: '/v1/get_schedule.php'
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('scheduleList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
  },
  mutations: {
    scheduleList(state, param) {

      const {period, period2, period3, period4} = param.data

      const list = []
      const now = $dayjs()
      const diffDay2 = now.diff($dayjs(period2), 'day') - 1
      const diffDay3 = now.diff($dayjs(period3), 'day') - 1
      const diffDay4 = now.diff($dayjs(period4), 'day') - 1

      if (diffDay2 <= 0){
        list.push({
          diffDay: diffDay2,
          dDay: `D-${Math.abs(diffDay2)}`,
          title:'응시원서접수',
          fotmatted: `${$dayjs(period).format('M/D')}~${$dayjs(period2).format('M/D')}`
        })
      }

      if (diffDay3 <= 0){
        list.push({
          diffDay: diffDay3,
          dDay: `D-${Math.abs(diffDay3)}`,
          title:'시험시행일',
          fotmatted: $dayjs(period3).format('M/D')
        })
      }

      if (diffDay4 <= 0){
        list.push({
          diffDay: diffDay4,
          dDay: `D-${Math.abs(diffDay4)}`,
          title:'최종합격자 발표일',
          fotmatted: $dayjs(period4).format('M/D')
        })
      }

      //일정 리스트
      state.status = {
        ...state.status,
        list: list
      }

    },
    reset(state){
      //상태 초기화
      Object.assign(state, deepCopy(initialState))
    },
  },
  getters:{
    getList(state){
      //일정 리스트
      return state.status.list
    },
  }
}