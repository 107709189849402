import qs from 'qs';
import {mapKeysCamelCase, mapKeysSnakeCase} from '@/utils/deepKeysMap';
import axios from '@/api/axios';
import _ from 'lodash';
import {EO_TOKEN, EO_USER} from '@/constants/Constants';
import User from '@/models/user';
import {deepFreeze} from '@/utils/deepFreeze';
import {deepCopy} from '@/utils/deepCopy';

export const LOGIN = 'auth/login'
export const REFRESH_TOKEN = 'auth/refreshToken'
export const SNS_LOGIN = 'auth/snsLogin'
export const EMAIL_CHECK = 'auth/emailCheck'
export const NICKNAME_CHECK = 'auth/nickNameCheck'
export const SNS_EMAIL_CHECK = 'auth/snsEmailCheck'
export const REGISTER = 'auth/register'
export const LOGOUT = 'auth/logout'
export const GET_TOKEN = 'auth/getToken'
export const GET_USER = 'auth/getUser'
export const IS_LOGGEDIN = 'auth/isLoggedIn'
export const SEND_HP_VALIDATION_CODE = 'auth/sendHpValidationCode'
export const HP_VALIDATION = 'auth/hpValidation'
export const PW_RESET = 'auth/pwReset'
export const REGISTER_PUSH_TOKEN = 'auth/registerPushToken'
export const PUSH_TOGGLE = 'auth/pushToggle'
export const LEAVE = 'auth/leave'
export const UPDATE = 'auth/update'
export const UPDATE_PROFILE_IMAGE = 'auth/updateProfileImage'


let initialState = { status: {}, user: null }

try {
  const user = JSON.parse(localStorage.getItem(EO_USER))
  initialState = { status: { loggedIn: true }, user }
} catch (e){
  console.log(e)
}

deepFreeze(initialState)

export const auth = {
  namespaced: true,
  state: deepCopy(initialState),
  actions: {
    login ({ commit }, user) {
      //로그인
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/login.php',
        data: qs.stringify(mapKeysSnakeCase(user))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('loginSuccess', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    refreshToken ({ commit }, token) {
      //토큰 갱신
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/refresh_token.php',
        data: qs.stringify({token: token})
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            const user = JSON.parse(localStorage.getItem(EO_USER))
            commit('refreshTokenSuccess', {user, refreshToken: finalData.token})
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    snsLogin ({ commit }, user) {
      //sns 로그인
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/sns_login.php',
        data: qs.stringify(mapKeysSnakeCase(user))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('loginSuccess', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    logout({ commit, state }) {
      //로그아웃
      let finalData = {
        result_code: '00',
        message: ''
      }
      const options = {
        method: 'POST',
        url: '/v1/del_token.php',
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            state = deepCopy(initialState)
            localStorage.removeItem(EO_USER)
            localStorage.removeItem(EO_TOKEN)
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    emailCheck({ commit }, form){
      //이메일 중복확인
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/email_check.php',
        data: qs.stringify(mapKeysSnakeCase(form))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    nickNameCheck({ commit }, form){
      //닉네임 중복확인
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/nick_check.php',
        data: qs.stringify(mapKeysSnakeCase(form))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    snsEmailCheck({ commit }, user){
      //sns 이메일 중복 확인
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/sns_email_check.php',
        data: qs.stringify(mapKeysSnakeCase(user))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    sendHpValidationCode({ commit }, form){
      //본인인증 코드 전송
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/send_hp_validation_code.php',
        data: qs.stringify(mapKeysSnakeCase(form))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    hpValidation({ commit }, form){
      //본인인증 코드 확인
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/hp_validation.php',
        data: qs.stringify(mapKeysSnakeCase(form))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    pwReset({ commit }, form){
      //비밀번호 초기화
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/pw_reset.php',
        data: qs.stringify(mapKeysSnakeCase(form))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    register({ commit }, form) {
      //회원가입
      let finalData = {
        result_code: '00',
        message: ''
      }

      let formData = new FormData()
      for ( const key in form ) {

        formData.append(key, form[key]);
      }

      const options = {
        method: 'POST',
        url: '/v1/member_join.php',
        headers: {'Content-Type': 'multipart/form-data'},
        data: formData
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)

            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    registerPushToken({ commit }, token) {

      //푸시 토큰 등록
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/register_push_token.php',
        data: qs.stringify(mapKeysSnakeCase({token: token}))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    pushToggle({ commit }) {
      //푸시 토글
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/push_toggle.php'
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('pushStateChanged', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    leave({ commit, state }) {
      //회원탈퇴
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/leave.php'
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            state = deepCopy(initialState)
            localStorage.removeItem(EO_USER)
            localStorage.removeItem(EO_TOKEN)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    update({ commit, state }, form) {
      //유저정보 수정
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/user_update.php',
        data: qs.stringify(mapKeysSnakeCase(form))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('userStateChanged', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    updateProfileImage({ commit, state }, image) {
      //프로필 이미지 업로드
      let finalData = {
        result_code: '00',
        message: ''
      }

      let formData = new FormData()
      formData.append('file', image);

      const options = {
        method: 'POST',
        url: '/v1/user_profile_image.php',
        headers: {'Content-Type': 'multipart/form-data'},
        data: formData
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('userProfileImageChanged', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    }

  },
  mutations: {
    loginSuccess(state, param) {
      state.status = { loggedIn: true }
      const user = new User(param)
      state.user = user
      localStorage.setItem(EO_TOKEN, JSON.stringify(user.token))
      localStorage.setItem(EO_USER, JSON.stringify(user))
    },
    refreshTokenSuccess(state, {user, refreshToken}) {
      //토큰값을 통한 자동로그인
      state.status = { loggedIn: true }
      user.token = refreshToken
      state.user = user
      localStorage.setItem(EO_TOKEN, JSON.stringify(user.token))
      localStorage.setItem(EO_USER, JSON.stringify(user))
    },
    pushStateChanged(state, param){
      state.user.isPush = param.isPush
    },
    userStateChanged(state, param){
      state.user.nick = param.nick
    },
    userProfileImageChanged(state, param){
      state.user.profile_image = param.profile_image
    },
  },
  getters:{
    getToken(state){
      return state.user.token
    },
    getUser(state){
      return state.user
    },
    isLoggedIn(state){
      return state.status.loggedIn
    }
  }
}