import qs from 'qs';
import {mapKeysCamelCase, mapKeysSnakeCase} from '@/utils/deepKeysMap';
import axios from '@/api/axios';
import _ from 'lodash';
import {deepFreeze} from '@/utils/deepFreeze';
import {deepCopy} from '@/utils/deepCopy';

let initialState = {
  status: {
    page:1,
    totalPage:1,
    list: []
  },
  isRead: false,
}

deepFreeze(initialState)

export const READ_CHECK = 'alarm/readCheck'
export const GET_READ = 'alarm/getRead'
export const DO_LIST = 'alarm/doList'
export const DO_READ = 'alarm/doRead'
export const GET_LIST = 'alarm/getList'
export const RESET = 'alarm/reset'
export const ALL_CHECK = 'alarm/allCheck'
export const CHECK = 'alarm/check'
export const DO_DELETE = 'alarm/doDelete'

export const alarm = {
  namespaced: true,
  state: deepCopy(initialState),
  actions: {
    readCheck ({ commit }) {
      //읽지않은 알림
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/get_alarm_read.php'
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('alarmState', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    doRead ({ commit }, no) {

      //읽지않은 알림
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/alarm_read.php',
        params: {
          'no': no
        }
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('alarmRead', no)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    doList({commit, state}){

      //알림 리스트 조회
      if (state.page > state.totalPage) {
        return
      }

      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'GET',
        url: '/v1/get_alarm_list.php',
        params: {
          'page': state.page
        }
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('alarmList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    doDelete({commit, dispatch}, no){

      //알림 삭제
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'GET',
        url: '/v1/del_alarm.php',
        params: {
          'no': no
        }
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
  },
  mutations: {
    alarmState(state, param) {
      //알림 읽음 여부
      state.isRead = +param.count > 0
    },
    alarmRead(state, no) {
      //알림 읽음
      Object.assign(state.status, {
        ...state.status,
        list: state.status.list.map(value => {
          if (value.no === no){
            value.read = true
          }

          return value
        })
      })
    },
    alarmList(state, param) {
      //알림 리스트
      state.status = {
        ...state.status,
        totalPage: param.max_page,
        page: +param.page + 1,
        list: _.merge(state.status.list, param.list)
      }
    },
    reset(state){
      //상태 초기화
      Object.assign(state, deepCopy(initialState))
    },
    allCheck(state, isCheck){
      //전체 토글
      state.status = {
        ...state.status,
        list: state.status.list.map(value => {
          value.check = isCheck
          return value
        })
      }
    },
    check(state, {index, isCheck}){
      //체크 토글
      state.status.list[index].check = isCheck
    }
  },
  getters:{
    getRead(state){
      //알림 읽음
      return state.isRead
    },
    getList(state){
      //알림 리스트
      return state.status.list
    },
  }
}