import axios from '@/api/axios';
import _ from 'lodash';
import {mapKeysCamelCase, mapKeysSnakeCase} from '@/utils/deepKeysMap';
import qs from 'qs';
import {deepFreeze} from '@/utils/deepFreeze';
import {deepCopy} from '@/utils/deepCopy';

let initialState = {
  status: {
    page:1,
    totalPage:1,
    list: [],
    view: {}
  },
}

deepFreeze(initialState)

export const DO_LIST = 'notice/doList'
export const RESET = 'notice/reset'
export const GET_LIST = 'notice/getList'
export const DO_VIEW = 'notice/doView'
export const GET_VIEW = 'notice/getView'

export const notice = {
  namespaced: true,
  state: deepCopy(initialState),
  actions: {
    doList({commit, state}){
      //공지사항 리스트 조회
      if (state.page > state.totalPage) {
        return
      }

      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/get_notice_list.php',
        params: {
          'page': state.page
        }
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('noticeList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    doView({commit, state}, no){
      //공지사항 상세 조회

      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/get_notice.php',
        params: {no: no}
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('noticeView', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
  },
  mutations: {
    noticeList(state, param) {
      //공지사항 리스트
      state.status = {
        ...state.status,
        totalPage: param.max_page,
        page: +param.page + 1,
        list: _.merge(state.status.list, param.list)
      }
    },
    reset(state){
      //상태 초기화
      Object.assign(state, deepCopy(initialState))
    },
    noticeView(state, param){
      //공지사항 상세
      state.status = {
        ...state.status,
        view: param.data
      }
    },
  },
  getters:{
    getList(state){
      //공지사항 리스트
      return state.status.list
    },
    getView(state){
      //공지사항 상세
      return state.status.view
    },
  }
}