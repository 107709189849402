//유저
export const EO_USER = 'eouser'

//유저 토큰
export const EO_TOKEN = 'eotoken'

//최초 실행 저장용 키
export const EO_IS_INSTALLED = 'eoisinstalled'

//무료체험 완료
export const EO_FREE_COMPLETE = 'eofreecomplete'

//중단 데이터 저장
export const EO_EX_SAVE = 'eoexsave'

//전공
export const MAJOR = [
  '방사선사',
  '물리치료사'
]

//대학교
export const UNIVERSITY = [
  '고려대학교',
  '대구보건대학교',
  '동남보건대학교',
  '신구대학교',
  '신한대학교',
  '원광보건대학교',
  '대전보건대학교',
  '광주보건대학교',
  '부산가톨릭대학교',
  '목포과학대학교',
  '안산대학교',
  '김천대학교',
  '마산대학교',
  '서해대학교',
  '수성대학교',
  '제주한라대학교',
  '을지대학교',
  '가천대학교',
  '광양보건대학교',
  '한서대학교',
  '가야대학교',
  '연세대학교',
  '남부대학교',
  '충북보건과학대학교',
  '한려대학교',
  '건양대학교',
  '대구가톨릭대학교',
  '동신대학교',
  '서라벌대학교',
  '전주대학교',
  '대원대학교',
  '선린대학교',
  '춘해보건대학교',
  '한국국제대학교',
  '한림성심대학교',
  '극동대학교',
  '동의과학대학교',
  '강원대학교',
  '백석문화대학교',
  '경산1대학교',
  '동서대학교',
  '동의대학교',
  '송호대학교',
  '청주대학교'
]

//물리치료사 부속 대학
export const UNIVERSITY2 = [
  '삼육대학교',
  '가천대학교',
  '용인대학교',
  '을지대학교',
  '강원대학교',
  '경동대학교',
  '상지대학교',
  '연세대학교 미래캠퍼스',
  '유원대학교',
  '청주대학교',
  '한국교통대학교',
  '건양대학교',
  '대전대학교',
  '우송대학교',
  '나사렛대학교',
  '남서울대학교',
  '단국대학교',
  '백석대학교',
  '선문대학교',
  '중부대학교',
  '한서대학교',
  '호서대학교',
  '우석대학교',
  '전주대학교',
  '호원대학교',
  '광주여자대학교',
  '남부대학교',
  '호남대학교',
  '동신대학교',
  '세한대학교',
  '경운대학교',
  '김천대학교',
  '대구대학교',
  '대구가톨리대학교',
  '대구한의대학교',
  '위덕대학교',
  '경성대학교',
  '동의대학교',
  '부산가톨릭대학교',
  '신라대학교',
  '가야대학교',
  '경남대학교',
  '영산대학교',
  '인제대학교',
  '한국국제대학교',
  '경복대학교',
  '동남보건대학교',
  '수원여자대학교',
  '신구대학교',
  '안산대학교',
  '여주대학교',
  '강릉영동대학교',
  '한림성심대학교',
  '강동대학교',
  '대원대학교',
  '대전과학기술대학교',
  '대전보건대학교',
  '신성대학교',
  '군장대학교',
  '전주비전대학교',
  '원광보건대학교',
  '광주보건대학교',
  '서영대학교',
  '광양보건대학교',
  '목포과학대학교',
  '전남과학대학교',
  '청암대학교',
  '대구과학대학교',
  '대구보건대학교',
  '영남이공대학교',
  '경북전문대학교',
  '구미대학교',
  '선린대학교',
  '안동과학대학교',
  '포항대학교',
  '호산대학교',
  '경남정보대학교',
  '동의과학대학교',
  '부산보건대학교',
  '울산과학대학교',
  '춘해보건대학교',
  '김해대학교',
  '마산대학교',
  '제주한라대학교'
]