import axios from '@/api/axios';
import _ from 'lodash';
import {mapKeysCamelCase, mapKeysSnakeCase} from '@/utils/deepKeysMap';
import qs from 'qs';
import {deepFreeze} from '@/utils/deepFreeze';
import {deepCopy} from '@/utils/deepCopy';

let initialState = {
  status: {

  },
}

deepFreeze(initialState)

export const FETCH_DATA = 'inapp/fetchData'
export const PURCHASE = 'inapp/purchase'
export const GET_TICKET = 'inapp/getTicket'
export const RESET = 'inapp/reset'
export const REVOKE = 'inapp/revoke'

export const inapp = {
  namespaced: true,
  state: deepCopy(initialState),
  actions: {
    fetchData({commit, state}){
      //결제 정보 조회
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'GET',
        url: '/v1/get_ticket.php'
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('setTicket', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    purchase({commit, state, dispatch}, param){
      //결제 영수증 저장
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/set_inapp_payment.php',
        data: qs.stringify(param)
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            dispatch('fetchData')
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    revoke({commit, state, dispatch}){
      //이용권 해지
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/revoke_inapp.php'
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            dispatch('fetchData')
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
  },
  mutations: {
    setTicket(state, param){
      state.status = param.data
    },
    reset(state){
      //상태 초기화
      Object.assign(state, deepCopy(initialState))
    },
  },
  getters:{
    getTicket(state){
      return state.status
    },
  }
}