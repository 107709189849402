import dayjs from 'dayjs';
import 'dayjs/locale/ko'
import duration from 'dayjs/plugin/duration'

export default {
  /**
   * Vue
   * @param {Vue} Vue
   */
  install: function (Vue) {
    dayjs.locale('ko')
    dayjs.extend(duration)
    Vue.config.globalProperties.$dayjs = dayjs;
  }
};