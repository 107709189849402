import axios from '@/api/axios';
import _ from 'lodash';
import {mapKeysCamelCase, mapKeysSnakeCase} from '@/utils/deepKeysMap';
import qs from 'qs';
import {deepFreeze} from '@/utils/deepFreeze';
import {deepCopy} from '@/utils/deepCopy';

let mainInitialState = {
  popular: [],
  free: null,
  share: null,
  job: null,
  my: null
}

deepFreeze(mainInitialState)

let myInitialState = {
  page:1,
  totalPage:1,
  list: [],
}

deepFreeze(myInitialState)

let freeInitialState = {
  popular: [],
  page:1,
  totalPage:1,
  list: [],
  sort: 0
}

deepFreeze(freeInitialState)

let shareInitialState = {
  popular: [],
  page:1,
  totalPage:1,
  list: [],
  sort: 0
}

deepFreeze(shareInitialState)

let jobInitialState = {
  popular: [],
  page:1,
  totalPage:1,
  list: [],
  sort: 0
}

deepFreeze(jobInitialState)

let initialState = {
  status: {
    main:deepCopy(mainInitialState),
    free:deepCopy(freeInitialState),
    share:deepCopy(shareInitialState),
    job:deepCopy(jobInitialState),
    my: deepCopy(myInitialState),
    view: null,
    reply: []
  },
}

deepFreeze(initialState)

export const DO_MAIN_POPULAR_LIST = 'community/doMainPopularList'
export const GET_MAIN_POPULAR_LIST = 'community/getMainPopularList'
export const MAIN_RESET = 'community/mainReset'
export const FREE_RESET = 'community/freeReset'
export const MY_RESET = 'community/myReset'
export const SHARE_RESET = 'community/shareReset'
export const JOB_RESET = 'community/jobReset'
export const DO_MAIN_LIST = 'community/doMainList'
export const GET_FREE = 'community/getFree'
export const GET_SHARE = 'community/getShare'
export const GET_JOB = 'community/getJob'
export const DO_FREE_POPULAR_LIST = 'community/doFreePopularList'
export const GET_FREE_POPULAR_LIST = 'community/getFreePopularList'
export const DO_SHARE_POPULAR_LIST = 'community/doSharePopularList'
export const GET_SHARE_POPULAR_LIST = 'community/getSharePopularList'
export const DO_JOB_POPULAR_LIST = 'community/doJobPopularList'
export const GET_JOB_POPULAR_LIST = 'community/getJobPopularList'
export const DO_FREE_LIST = 'community/doFreeList'
export const DO_MY_LIST = 'community/doMyList'
export const GET_FREE_LIST = 'community/getFreeList'
export const GET_MY_LIST = 'community/getMyList'
export const DO_SHARE_LIST = 'community/doShareList'
export const GET_SHARE_LIST = 'community/getShareList'
export const DO_JOB_LIST = 'community/doJobList'
export const GET_JOB_LIST = 'community/getJobList'
export const DO_VIEW = 'community/doView'
export const GET_VIEW = 'community/getView'
export const VIEW_RESET = 'community/viewReset'
export const DO_REPLY = 'community/doReply'
export const REPLY_RESET = 'community/replyReset'
export const GET_REPLY = 'community/getReply'
export const DO_GOOD = 'community/doGood'
export const DO_COMMENT_WRITE = 'community/doCommentWrite'
export const DO_COMMENT_DELETE = 'community/doCommentDelete'
export const DO_COMMENT_UPDATE = 'community/doCommentUpdate'
export const DO_REPORT = 'community/doReport'
export const DO_WRITE = 'community/doWrite'
export const DO_DELETE = 'community/doDelete'
export const DO_UPDATE = 'community/doUpdate'
export const DO_DELETE_PICTURE = 'community/doDeletePicture'
export const DO_BLOCK = 'community/doBlock'


export const community = {
  namespaced: true,
  state: deepCopy(initialState),
  actions: {
    doMainPopularList({commit, state}){
      //인기 커뮤니티
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/get_popular_list.php',
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('popularList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    doMainList({commit, state}, sort){
      //커뮤니티 메인글
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/get_community_main_list.php',
        data: qs.stringify(mapKeysSnakeCase({sort: sort}))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('mainList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    doFreePopularList({commit, state}){
      //자유커뮤니티 인기 글
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/get_free_popular_list.php',
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('freePopularList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    doSharePopularList({commit, state}){
      //정보공유커뮤니티 인기 글
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/get_share_popular_list.php',
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('sharePopularList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    doJobPopularList({commit, state}){
      //취업진로커뮤니티 인기 글
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/get_job_popular_list.php',
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('jobPopularList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    doMyList({commit, state}){

      //내 커뮤니티 메인글
      if (+state.status.my.page > +state.status.my.totalPage) {
        return
      }

      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/get_my_community_list.php',
        params: {
          'page': state.status.my.page
        }
      }

      return axios(options).then(
        response => {

          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('myList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    doFreeList({commit, state}, sort){

      //자유커뮤니티 메인글
      if (+state.status.free.page > +state.status.free.totalPage) {
        return
      }

      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/get_community_free_list.php',
        params: {
          'page': state.status.free.page,
          'sort': sort
        }
      }

      return axios(options).then(
        response => {

          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('freeList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    doShareList({commit, state}, sort){

      //정보공유커뮤니티 메인글
      if (state.status.share.page > state.status.share.totalPage) {
        return
      }

      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/get_community_share_list.php',
        params: {
          'page': state.status.share.page,
          'sort': sort
        }
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('shareList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    doJobList({commit, state}, sort){

      //취업진로커뮤니티 메인글
      if (state.status.job.page > state.status.job.totalPage) {
        return
      }

      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/get_community_job_list.php',
        params: {
          'page': state.status.job.page,
          'sort': sort
        }
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('jobList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    doView({commit, state}, wr_id){
      //커뮤니티 상세

      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/get_community_view.php',
        data: qs.stringify(mapKeysSnakeCase({wr_id: wr_id}))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('view', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    doReply({commit, state}, wr_id){
      //커뮤니티 상세 댓글

      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/get_community_reply.php',
        data: qs.stringify(mapKeysSnakeCase({wr_id: wr_id}))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('reply', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    doGood({commit, state}, wr_id){
      //공감
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/get_community_good.php',
        data: qs.stringify(mapKeysSnakeCase({wr_id: wr_id}))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('good', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    doCommentWrite({commit, state, dispatch }, form){
      //댓글작성
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/set_comment.php',
        data: qs.stringify(mapKeysSnakeCase(form))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            dispatch('doReply', form.wr_id)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    doCommentDelete({commit, state, dispatch }, form){
      //댓글삭제
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/set_comment_delete.php',
        data: qs.stringify(mapKeysSnakeCase(form))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            dispatch('doReply', form.wr_id)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    doCommentUpdate({commit, state, dispatch }, form){
      //댓글수정
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/set_comment_update.php',
        data: qs.stringify(mapKeysSnakeCase(form))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            dispatch('doReply', form.wr_id)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    doReport({commit }, form){
      //신고
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/set_community_report.php',
        data: qs.stringify(mapKeysSnakeCase(form))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    doWrite({commit }, form){
      //등록
      let finalData = {
        result_code: '00',
        message: ''
      }

      let formData = new FormData()
      for ( const key in form ) {

        if (key === 'bf_file'){
          const files = form[key]

          files.forEach((file, index) => {
            formData.append(`${key}[${index}]`, file);
          })
        } else {
          formData.append(key, form[key]);
        }
      }

      const options = {
        method: 'POST',
        url: '/v1/set_community_write.php',
        headers: {'Content-Type': 'multipart/form-data'},
        data: formData
      }

      return axios(options).then(
        response => {

          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(finalData)
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )

    },
    doDelete({commit }, form){
      //삭제
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/set_community_delete.php',
        data: qs.stringify(mapKeysSnakeCase(form))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    doUpdate({commit }, form){
      //수정
      let finalData = {
        result_code: '00',
        message: ''
      }

      let formData = new FormData()
      for ( const key in form ) {
        if (key === 'bf_file'){
          const files = form[key]
          files.forEach((file, index) => {
            formData.append(`${key}[${index}]`, file);
          })
        } else {
          formData.append(key, form[key]);
        }
      }

      const options = {
        method: 'POST',
        url: '/v1/set_community_update.php',
        headers: {'Content-Type': 'multipart/form-data'},
        data: formData
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(finalData)
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    doDeletePicture({commit }, form){
      //사진 삭제
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/set_community_delete_picture.php',
        data: qs.stringify(mapKeysSnakeCase(form))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(finalData)
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
    doBlock({commit }, form){
      //차단
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'POST',
        url: '/v1/set_community_block.php',
        data: qs.stringify(mapKeysSnakeCase(form))
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },

  },
  mutations: {
    popularList(state, param) {
      //인기 커뮤니티 리스트
      state.status.main = {
        ...state.status.main,
        popular: param.list
      }
    },
    mainList(state, param) {
      //커뮤니티 메인글
      state.status.main = {
        ...state.status.main,
        free: param.free,
        share: param.share,
        job: param.job,
      }
    },
    mainReset(state){
      //상태 초기화
      Object.assign(state.status.main, deepCopy(mainInitialState))
    },
    freeReset(state){
      const popular = deepCopy(state.status.free.popular)
      //상태 초기화
      Object.assign(state.status.free, deepCopy(freeInitialState))
      Object.assign(state.status.free.popular, popular)
    },
    myReset(state){
      //상태 초기화
      Object.assign(state.status.my, deepCopy(myInitialState))
    },
    shareReset(state){
      const popular = deepCopy(state.status.share.popular)
      //상태 초기화
      Object.assign(state.status.share, deepCopy(shareInitialState))
      Object.assign(state.status.share.popular, popular)
    },
    jobReset(state){
      const popular = deepCopy(state.status.job.popular)
      //상태 초기화
      Object.assign(state.status.job, deepCopy(jobInitialState))
      Object.assign(state.status.job.popular, popular)
    },
    freePopularList(state, param) {
      //자유커뮤니티 인기 글
      state.status.free = {
        ...state.status.free,
        popular: param.list
      }
    },
    sharePopularList(state, param) {
      //정보공유커뮤니티 인기 글
      state.status.share = {
        ...state.status.share,
        popular: param.list
      }
    },
    jobPopularList(state, param) {
      //취업진로커뮤니티 인기 글
      state.status.job = {
        ...state.status.job,
        popular: param.list
      }
    },
    myList(state, param) {

      //내커뮤니티 메인글
      state.status.my = {
        ...state.status.my,
        totalPage: param.max_page,
        page: +param.page + 1,
        list: [...state.status.my.list, ...param.list]
      }
    },
    freeList(state, param) {

      //자유커뮤니티 메인글
      state.status.free = {
        ...state.status.free,
        totalPage: param.max_page,
        page: +param.page + 1,
        list: [...state.status.free.list, ...param.list]
      }
    },
    shareList(state, param) {
      //정보공유커뮤니티 메인글
      state.status.share = {
        ...state.status.share,
        totalPage: param.max_page,
        page: +param.page + 1,
        list: [...state.status.share.list, ...param.list]
      }
    },
    jobList(state, param) {
      //취업진로커뮤니티 메인글
      state.status.job = {
        ...state.status.job,
        totalPage: param.max_page,
        page: +param.page + 1,
        list: [...state.status.job.list, ...param.list]
      }
    },
    view(state, param) {
      //커뮤니티 상세
      state.status = {
        ...state.status,
        view: param.data
      }
    },
    viewReset(state){
      //상태 초기화
      state.status.view = null
    },
    reply(state, param) {
      //커뮤니티 상세 댓글
      state.status = {
        ...state.status,
        reply: param.list
      }
    },
    replyReset(state){
      //상태 초기화
      state.status.reply = []
    },
    good(state, param){
      //공감
      state.status.view = {
        ...state.status.view,
        wr_good: param.good
      }
    },
  },
  getters:{
    getMainPopularList(state){
      //인기 커뮤니티 리스트
      return state.status.main.popular
    },
    getFree(state){
      //메인 자유커뮤니티
      return state.status.main.free
    },
    getShare(state){
      //메인 정보공유커뮤니티
      return state.status.main.share
    },
    getJob(state){
      //메인 취업진로커뮤니티
      return state.status.main.job
    },
    getFreePopularList(state){
      //자유커뮤니티 인기 글
      return state.status.free.popular
    },
    getSharePopularList(state){
      //정보공유커뮤니티 인기 글
      return state.status.share.popular
    },
    getJobPopularList(state){
      //취업진로커뮤니티 인기 글
      return state.status.job.popular
    },
    getMyList(state){
      //내커뮤니티 리스트
      return state.status.my.list
    },
    getFreeList(state){
      //자유커뮤니티 리스트
      return state.status.free.list
    },
    getShareList(state){
      //정보공유커뮤니티 리스트
      return state.status.share.list
    },
    getJobList(state){
      //취업진로커뮤니티 리스트
      return state.status.job.list
    },
    getView(state){
      //커뮤니티 상세
      return state.status.view
    },
    getReply(state){
      //커뮤니티 상세 댓글
      return state.status.reply
    },
  }
}