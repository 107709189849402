import axios from '@/api/axios';
import _ from 'lodash';
import {mapKeysCamelCase} from '@/utils/deepKeysMap';
import $dayjs from 'dayjs';
import {deepFreeze} from '@/utils/deepFreeze';
import {deepCopy} from '@/utils/deepCopy';

let initialState = {
  status: {
    list: []
  },
}

deepFreeze(initialState)

export const DO_LIST = 'banner/doList'
export const GET_LIST = 'banner/getList'
export const RESET = 'banner/reset'

export const banner = {
  namespaced: true,
  state: deepCopy(initialState),
  actions: {
    doList({commit, state}){
      //배너 리스트 조회
      let finalData = {
        result_code: '00',
        message: ''
      }

      const options = {
        method: 'GET',
        url: '/v1/get_banner.php',
      }

      return axios(options).then(
        response => {
          if (response.data.result_code === '00') {
            finalData = _.merge(finalData, response.data)
            commit('bannerList', finalData)
            return Promise.resolve(mapKeysCamelCase(finalData))
          } else {
            return Promise.reject(response.data)
          }
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
    },
  },
  mutations: {
    bannerList(state, param) {
      //배너 리스트
      state.status = {
        ...state.status,
        list: param.list
      }
    },
    reset(state){
      //상태 초기화
      Object.assign(state, deepCopy(initialState))
    },
  },
  getters:{
    getList(state){
      //배너 리스트
      return state.status.list
    },
  }
}