
<template>
  <div id="wrapper">
      <Subheader/>
      <slot />
  </div>
</template>

<script>
import Subheader from '@/components/Subheader.vue'

export default {
  name: 'DefaultLayout',
  data() {
    return {
      menuVisible: true,
    }
  },
  components: {
    Subheader
  },
  computed: {
    name: function() {
      return this.$route.name ? this.$route.name : ''
    }
  }
}

</script>
